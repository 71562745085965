<template>
  <div>
    <div>

      <!-- Search Button -->
      <b-row>
        <b-col class="mb-1" cols="12" md="4">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon"/>
              </b-input-group-prepend>
              <b-form-input
                  id="icons-search"
                  v-model="filters['search']"
                  placeholder="Search"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Select Location  -->


        <!-- Create Programme Button -->

        <!--      <b-col cols="12" md="4" ></b-col>-->

        <b-col class="flex-float-right" cols="12" md="2">
          <b-form-group>

            <flat-pickr
                v-model="formStartDate"
                :config="{ enableTime: false, dateFormat: 'd-m-Y', locale: {firstDayOfWeek: 1}}"
                class="form-control"
                placeholder="Form Date"

            />
          </b-form-group>
        </b-col>
        <b-col class="flex-float-right" cols="12" md="2">
          <b-form-group>


            <flat-pickr
                v-model="formEndDate"
                :config="{ enableTime: false, dateFormat: 'd-m-Y', minDate:formStartDate, locale: {firstDayOfWeek: 1} }"
                class="form-control"
                placeholder="Form Date"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <!--          <b-form-group>-->
          <!--            <v-select-->

          <!--                label="title1"-->
          <!--                placeholder="All Locations"-->
          <!--                :options="[-->
          <!--                            {title1:'United Kingdom', value:'hdhdh'},-->
          <!--                            {title1:'United States', value:'hdhdh'},-->
          <!--                            {title1:'Sri Lanka', value:'hdhdh'},-->
          <!--                            ]"-->
          <!--            />-->
          <!--          </b-form-group>-->
        </b-col>
      </b-row>


    </div>
    <b-row>
      <b-col cols="12">
        <div v-if="pagination.totalRows ===0" class="text-center">
          <svg class="mt-2" fill="none" height="250" viewBox="0 0 100 100" width="250"
               xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" d="M50 0C77.6144 0 100 22.3856 100 50C100 77.6144 77.6144 100 50 100C22.3856 100 0 77.6144 0 50C0 22.3856 22.3856 0 50 0Z"
                  fill="#F0F8FC"
                  fill-rule="evenodd"/>
            <path clip-rule="evenodd" d="M75.5319 27.1289C77.0005 27.1289 78.1915 28.3193 78.1915 29.7885C78.1915 31.2571 77.0005 32.4481 75.5319 32.4481C74.0633 32.4481 72.8723 31.2571 72.8723 29.7885C72.8723 28.3193 74.0633 27.1289 75.5319 27.1289Z"
                  fill="#DCEEF8"
                  fill-rule="evenodd"/>
            <path clip-rule="evenodd" d="M81.9148 18.6172C83.9712 18.6172 85.6382 20.2842 85.6382 22.3406C85.6382 24.397 83.9712 26.064 81.9148 26.064C79.8584 26.064 78.1914 24.397 78.1914 22.3406C78.1914 20.2842 79.8584 18.6172 81.9148 18.6172Z"
                  fill="#DCEEF8"
                  fill-rule="evenodd"/>
            <path clip-rule="evenodd" d="M72.8724 19.6797C73.4602 19.6797 73.9363 20.1558 73.9363 20.7435C73.9363 21.3313 73.4602 21.8073 72.8724 21.8073C72.2847 21.8073 71.8086 21.3313 71.8086 20.7435C71.8086 20.1558 72.2847 19.6797 72.8724 19.6797Z"
                  fill="#DCEEF8"
                  fill-rule="evenodd"/>
            <path
                d="M41.2478 53.7681H59.8909M41.2478 63.0926H53.6776M50.5707 41.1139L50.7501 23.2678C50.7679 21.4981 52.9063 20.6219 54.1608 21.8703L70.1105 37.7434C71.3683 38.9952 70.4939 41.1434 68.7193 41.1609L50.7978 41.3373C50.6723 41.3373 50.5707 41.2362 50.5707 41.1139Z"
                stroke="#1B9AAA" stroke-linecap="round" stroke-width="2"/>
            <path clip-rule="evenodd" d="M28.1914 74.7162V24.7482C28.1914 22.2429 30.2239 20.2109 32.7313 20.2109H52.1111C52.7132 20.2109 53.2903 20.4503 53.7164 20.8758L71.0318 38.1897C71.4574 38.6152 71.6967 39.195 71.6967 39.796V74.7162C71.6967 77.2216 69.6648 79.2535 67.1542 79.2535H32.7313C30.2239 79.2535 28.1914 77.2216 28.1914 74.7162Z"
                  fill-rule="evenodd"
                  stroke="#171822" stroke-linecap="round" stroke-width="2"/>
          </svg>
          <p class="mt-2">No connected medical practices.</p>

        </div>


        <b-table
            v-else
            ref="practice_table"
            :current-page="currentPage"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="filterOn"
            :items="loadPractices"
            :per-page="pagination.perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            class="text-left custom_align mobile_table_css tbl-nl"
            hover
            responsive
        >
          <!--          @row-clicked="toggleDetails"-->
          <!-- Hidden Details Button  -->
          <template #cell(organizationPayment)="data">
            £{{ organizationTotal(data.item.includedUsers) }}
          </template>


          <template #cell(staffPayment)="data">
            £{{ staffUsers(data.item.excludedUsers) }}
          </template>

          <template #cell(settings)="data">

            <feather-icon
                v-b-tooltip.hover.top="'Add or Remove Users '"
                icon="SettingsIcon"

                size="18"
                @click="openUserAddSidebar(data.item)"/>


          </template>
          <template #cell(download)="data">

            <feather-icon
                v-b-tooltip.hover.top="'Preview and Print '"
                icon="DownloadIcon"

                size="18"
                @click="openPrintModel(data.item)"/>


          </template>
          <template #cell(add)="data">

            <feather-icon
                v-if="!data.item['_showDetails']"
                icon="ChevronRightIcon"
                size="18"
                @click="toggleDetails(data.item)"/>

            <feather-icon
                v-else
                icon="ChevronDownIcon"
                size="18"
                @click="toggleDetails(data.item)"/>


          </template>


          <!-- Hidden Details  -->

          <template #row-details="row">
            <div>
              <b-overlay
                  :show="rowLoading"
                  rounded="sm"
              >
                <div class="flex-column flex-md-row  ">

                  <!--                <h5 class="font-weight-700 ml-2 text-left">Service Charge</h5>-->
                  <!--                <div style="background-color:#EFF7FC">-->
                  <!--                  <b-row class="py-1 ml-0">-->
                  <!--                    <b-col cols="5">-->
                  <!--                      47 Crown Street, London W1H 8BR-->
                  <!--                    </b-col>-->
                  <!--                    <b-col class="font-weight-700 text-right" cols="2">-->
                  <!--                      Total hours: 120-->
                  <!--                    </b-col>-->
                  <!--                    <b-col class="font-weight-700 text-right" cols="2">-->
                  <!--                      Charge p/h: £35.00-->
                  <!--                    </b-col>-->
                  <!--                    <b-col class="font-weight-700 text-right" cols="2">-->
                  <!--                      Total: £4,200.00-->
                  <!--                    </b-col>-->

                  <!--                  </b-row>-->
                  <!--                </div>-->

                  <div class="" style="background-color:#EFF7FC">
                    <b-row class="py- font-weight-700 ml-0 bg-white">
                      <b-col cols="4">
                        <h5 class="font-weight-700 text-left">Organisation payment</h5>
                      </b-col>

                      <!--                  <b-row class="py-1 font-weight-700 ml-0" >-->
                      <!--                    <b-col cols="5">-->

                      <!--                    </b-col>-->
                      <b-col class="text-left" cols="3">
                        Total hours: {{ totalHoursCalculation(includedUsers) }}
                      </b-col>
                      <!--                    <b-col class="text-right" cols="2">-->
                      <!--                      Rate p/h: £50.00-->
                      <!--                    </b-col>-->
                      <b-col class="text-left" cols="2">
                        Rate p/h: -
                      </b-col>
                      <b-col class="text-left" cols="3">
                        Total: £{{ organizationTotal(includedUsers) }}
                      </b-col>
                    </b-row>

                    <b-row v-for="user in includedUsers" :key="user.name" class="ml-0" style="padding:8px 0">
                      <b-col cols="4">
                        {{ user.name }}
                      </b-col>
                      <b-col class="text-left" cols="3">
                        {{ user.total_hours }}
                      </b-col>
                      <b-col class="text-left" cols="2">
                        <span
                            v-if="(user.total_amount).min_rate ===(user.total_amount).max_rate">£{{ (user.total_amount).max_rate }}</span>
                        <span
                            v-if="(user.total_amount).min_rate !==(user.total_amount).max_rate">£{{ (user.total_amount).min_rate }} - £{{ (user.total_amount).max_rate }}</span>
                        <span v-if="(user.total_amount).max_rate === undefined">0.00</span>
                      </b-col>
                      <b-col class="text-left" cols="2">
                        <span v-if="(user.total_amount).total === undefined"> £0.00</span>
                        <span v-else> £{{ (user.total_amount).total }}</span>
                      </b-col>
                    </b-row>
                  </div>


                  <div class="" style="background-color:#EFF7FC">
                    <b-row class="py- font-weight-700 ml-0 bg-white">
                      <b-col cols="4">
                        <h5 class="font-weight-700 text-left">staff payment</h5>
                      </b-col>

                      <!--                  <b-row class="py-1 font-weight-700 ml-0" >-->
                      <!--                    <b-col cols="5">-->

                      <!--                    </b-col>-->
                      <b-col class="text-left" cols="3">
                        Total hours: {{ totalHoursCalculation(excludedUsers) }}
                      </b-col>
                      <!--                    <b-col class="text-right" cols="2">-->
                      <!--                      Rate p/h: £50.00-->
                      <!--                    </b-col>-->
                      <b-col class="text-left" cols="2">
                        Rate p/h: -
                      </b-col>
                      <b-col class="text-left" cols="3">
                        Total: £{{ organizationTotal(excludedUsers) }}
                      </b-col>
                    </b-row>

                    <b-row v-for="user in excludedUsers" :key="user.id" class="ml-0" style="padding:8px 0">
                      <b-col cols="4">
                        {{ user.name }}
                      </b-col>
                      <b-col class="text-left" cols="3">
                        {{ user.total_hours }}
                      </b-col>
                      <b-col class="text-left" cols="2">
                        <span
                            v-if="(user.total_amount).min_rate ===(user.total_amount).max_rate">£{{ (user.total_amount).max_rate }}</span>
                        <span
                            v-if="(user.total_amount).min_rate !==(user.total_amount).max_rate">£{{ (user.total_amount).min_rate }} - £{{ (user.total_amount).max_rate }}</span>
                        <span v-if="(user.total_amount).max_rate === undefined">0.00</span>
                      </b-col>
                      <b-col class="text-left" cols="2">
                        <span v-if="(user.total_amount).total === undefined"> £0.00</span>
                        <span v-else> £{{ (user.total_amount).total }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-overlay>

            </div>
          </template>

        </b-table>
      </b-col>

      <!-- Pagination -->
      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
      <span v-if="pagination.totalRows !==0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{
          pagination.totalRows
        }} entries</span>
      </b-col>
      <!-- Pagination -->
      <b-col
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
          cols="12"
          sm="6"
      >

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>

        </b-pagination>

      </b-col>
    </b-row>


    <!--    <AddOrRemoveUsersSideBar-->
    <!--        ref="AddOrRemoveUsers"-->
    <!--        :is-users-sidebar-active="isUsersSidebarActive"-->
    <!--        @toggleSideBars="toggleSideBars()"-->
    <!--        @dataRefresh="dataRefresh()"-->
    <!--    />-->
    <users-side-bar
        ref="userSideBar"
        :is-users-sidebar-active="isUsersSidebarActive"
        :practice-data="SelectedPractice"
        @dataRefresh="dataRefresh()"
        @toggleSideBars="toggleSideBars()"
    />

    <!--    <b-button @click="openUserAddSidebar()">Test</b-button>-->
    <!--    <b-sidebar-->
    <!--        id="is-users-sidebar-active"-->
    <!--        :visible="isUsersSidebarActive"-->
    <!--        backdrop-->
    <!--        bg-variant="white"-->
    <!--        no-header-->
    <!--        right-->
    <!--        shadow-->
    <!--        sidebar-class="sidebar-lg"-->
    <!--        @change="(value) => test(value)"-->
    <!--    >-->
    <!--      <template #default="{ hide }">-->
    <!--        &lt;!&ndash; Header &ndash;&gt;-->
    <!--        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">-->
    <!--          <h4 class="mb-0">-->
    <!--            Add Users-->
    <!--          </h4>-->

    <!--          <feather-icon-->
    <!--              class="ml-1 cursor-pointer"-->
    <!--              icon="XIcon"-->
    <!--              size="16"-->
    <!--              @click="hide"-->
    <!--          />-->

    <!--        </div>-->
    <!--        <div>-->
    <!--          hdhdhdhdh-->

    <!--        </div>-->

    <!--      </template>-->
    <!--    </b-sidebar>-->

    <b-modal
        id="modal-center"
        ref="modal-print"
        button-size="sm"
        centered
        ok-title="Print"
        size="lg"
        @ok="download"
    >
      <div id="printEliment">
        <b-row class="ml-0">
          <b-col cols="12" md="8">
            <svg fill="none" height="37" viewBox="30 0 115 48" width="150" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M16.9295 10.6556C19.917 10.6556 22.1411 11.5685 23.6017 13.3942C25.0954 15.1867 25.8423 17.9087 25.8423 21.5602V36.6473H16.8299V21.9585C16.8299 20.3983 16.5643 19.2863 16.0332 18.6224C15.5021 17.9253 14.6722 17.5768 13.5436 17.5768C12.1826 17.5768 11.0871 18.0415 10.2573 18.971C9.42739 19.8672 9.01245 21.0622 9.01245 22.556V36.6473H0V0H9.01245V14.4398C10.971 11.917 13.61 10.6556 16.9295 10.6556Z"
                  fill="#171822"/>
              <path
                  d="M57.2128 11.3527L45.6111 37.5436C44.0842 40.9627 42.1588 43.4523 39.8352 45.0125C37.5447 46.6058 34.7066 47.6017 31.3207 48L29.2294 41.1286C31.553 40.6639 33.2958 40.0664 34.4576 39.3361C35.6194 38.6058 36.4991 37.5934 37.0966 36.2988L37.7439 34.9046L27.4368 11.3527H36.7978L42.4742 25.9419L48.3497 11.3527H57.2128Z"
                  fill="#171822"/>
              <path
                  d="M74.8689 10.7552C76.0971 10.7552 77.0432 10.9378 77.7071 11.3029V19.5187C76.2797 18.8548 74.9021 18.5228 73.5743 18.5228C69.7901 18.5228 67.8979 20.332 67.8979 23.9502V36.6473H58.8855V18.6224C58.8855 15.9004 58.7527 13.4772 58.4872 11.3527H66.9519L67.4996 15.5851C68.0971 14.0249 69.0598 12.8299 70.3876 12C71.7154 11.1701 73.2092 10.7552 74.8689 10.7552Z"
                  fill="#171822"/>
              <path
                  d="M103.147 24.8963H87.2634C87.4293 26.8548 87.9936 28.2656 88.9563 29.1286C89.919 29.9917 91.3297 30.4232 93.1887 30.4232C94.4501 30.4232 95.7115 30.2241 96.9729 29.8257C98.2343 29.3942 99.3795 28.8133 100.409 28.083L102.699 34.0581C101.438 34.9876 99.9107 35.7178 98.1181 36.249C96.3256 36.7801 94.5331 37.0456 92.7405 37.0456C89.8526 37.0456 87.3463 36.5145 85.2219 35.4523C83.0974 34.39 81.4708 32.8797 80.3422 30.9212C79.2136 28.9295 78.6492 26.5892 78.6492 23.9004C78.6492 21.3444 79.1804 19.0705 80.2426 17.0788C81.338 15.0539 82.8318 13.4772 84.7239 12.3485C86.6492 11.2199 88.8401 10.6556 91.2965 10.6556C93.6866 10.6556 95.7779 11.1867 97.5704 12.249C99.3629 13.3112 100.741 14.8382 101.703 16.8299C102.666 18.7884 103.147 21.0788 103.147 23.7012V24.8963ZM91.5455 16.8299C89.3214 16.8299 87.9273 18.1245 87.3629 20.7137H95.4293C95.0974 18.1245 93.8028 16.8299 91.5455 16.8299Z"
                  fill="#171822"/>
              <path d="M107.52 29.8906H114.633V37.0038H107.52V29.8906Z" fill="#00C49A"/>
            </svg>
          </b-col>
          <b-col cols="12" md="4"></b-col>
        </b-row>
        <b-row class="ml-0">
          <!-- {{SelectedPracticeData.includedUsers[0].shifts[0].p_c_n_programme_id}} -->
          <b-col cols="12" md="8">
            <br><span>{{ SelectedPracticeData.owner_name }}</span>
            <br><span>{{ SelectedPracticeData.name }}</span>
            <br><span>{{ SelectedPracticeData.address }}</span>
            <br><span>{{ SelectedPracticeData.city }}</span>
            <br><span>{{ SelectedPracticeData.postal_code }}</span>
          </b-col>
          <b-col cols="12" md="4">
            <br><span style="font-weight: bold">Payment made by:</span>
            <br><span> {{ pcn.name }}</span><br>
            <!--            <br><span style="font-weight: bold">Programme:</span>-->
            <!--            <br><span> {{SelectedPracticeData.programmes}}</span>-->
            <!--            <br><span>Programme name</span>-->
          </b-col>

        </b-row>

        <b-row class="ml-0 mb-2">
          <b-col cols="12" md="12">
            <br><span class="font-weight-700"> Statement of payment</span>
            <br><span>{{ momentFormat(selectedStartDate, 'DD-MMM-YY') }}- {{ momentFormat(selectedEndDate, 'DD-MMM-YY') }}</span>
            <!--            <br><span class="font-weight-700">Total: £{{ (Number(organizationTotal(includedUsers)).toFixed(2) + Number(staffUsers(excludedUsers))).toFixed(2) }}</span>-->
          </b-col>
        </b-row>


        <b-row class="py-1 font-weight-700 ml-0" style="background-color: #c6e0e9">
          <b-col cols="5">
            <h5 class="font-weight-700 text-left">Payment to Organization</h5>
          </b-col>

          <!--                  <b-row class="py-1 font-weight-700 ml-0" >-->
          <!--                    <b-col cols="5">-->

          <!--                    </b-col>-->
          <b-col class="text-right" cols="3">
            <!-- Total hours: {{totalHoursCalculation(includedUsers)}} -->
          </b-col>
          <!--                    <b-col class="text-right" cols="2">-->
          <!--                      Rate p/h: £50.00-->
          <!--                    </b-col>-->
          <b-col class="text-right" cols="3">
            Total: £{{ organizationTotal(includedUsers) }}
          </b-col>
        </b-row>

        <!--            <b-row class="py-1 font-weight-700 ml-0 mt-1"  style="background-color: #C0C0C0">-->
        <!--              <b-col cols="5">-->
        <!--                <h5 class="font-weight-700 text-left">Clinical Pharmacists</h5>-->
        <!--              </b-col>-->

        <!--                  <b-row class="py-1 font-weight-700 ml-0" >-->
        <!--                    <b-col cols="5">-->

        <!--                    </b-col>-->

        <!--                    <b-col class="text-right" cols="2">-->
        <!--                      Rate p/h: £50.00-->
        <!--                    </b-col>-->

        <!--            </b-row>-->
        <b-row class="ml-0" style="padding:8px 0">
          <b-col class="font-weight-700" cols="5">
            Name
          </b-col>
          <b-col class="font-weight-700 text-left" cols="2">
            Hour
          </b-col>
          <b-col class="font-weight-700 text-left" cols="3">
            Rate ph
          </b-col>
          <b-col class="font-weight-700 text-left" cols="2">
            Total
          </b-col>
        </b-row>

        <b-row v-for="user in includedUsers" :key="user.name" class="ml-0" style="padding:8px 0">
          <b-col cols="5">
            {{ user.name }}
          </b-col>
          <b-col class="text-left" cols="2">
            {{ user.total_hours }}
          </b-col>
          <b-col class="text-left" cols="3">
            <span
                v-if="(user.total_amount).min_rate ===(user.total_amount).max_rate">£{{ (user.total_amount).max_rate }}</span>
            <span
                v-if="(user.total_amount).min_rate !==(user.total_amount).max_rate">£{{ (user.total_amount).min_rate }} - £{{ (user.total_amount).max_rate }}</span>
            <span v-if="(user.total_amount).max_rate === undefined">0.00</span>

          </b-col>
          <b-col class="text-left" cols="2">
            <span v-if="(user.total_amount).total === undefined"> £0.00</span>
            <span v-else> £{{ (user.total_amount).total }}</span>
          </b-col>
        </b-row>
        <b-row class="ml-0" style="padding:8px 0">
          <b-col class="font-weight-700" cols="5">
            Total
          </b-col>
          <b-col class="font-weight-700" cols="5">
            {{ hourTotal(includedUsers) }}
          </b-col>
          <b-col class="font-weight-700" cols="2">
            £{{ organizationTotal(includedUsers) }}
          </b-col>
        </b-row>


        <b-row class="py-1 font-weight-700 ml-0 mt-1" style="background-color: #c6e0e9">
          <b-col cols="5">
            <h5 class="font-weight-700 text-left">Payment to staff</h5>
          </b-col>
          <b-col class="text-right" cols="3">
            <!-- Total hours:{{totalHoursCalculation(excludedUsers)}} -->
          </b-col>
          <!--                    <b-col class="text-right" cols="2">-->
          <!--                      Rate p/h: £50.00-->
          <!--                    </b-col>-->
          <b-col class="text-right" cols="3">
            Total: £{{ staffUsers(excludedUsers) }}
          </b-col>
        </b-row>


        <b-row v-for="user in excludedUsers" :key="user.name" class="ml-0" style="padding:8px 0">
          <b-col cols="5">
            {{ user.name }}
          </b-col>
          <b-col class="text-left" cols="2">
            {{ user.total_hours }}
          </b-col>
          <b-col class="text-left" cols="3">
            <span
                v-if="(user.total_amount).min_rate ===(user.total_amount).max_rate">£{{ (user.total_amount).max_rate }}</span>
            <span
                v-if="(user.total_amount).min_rate !==(user.total_amount).max_rate">£{{ (user.total_amount).min_rate }} - £{{ (user.total_amount).max_rate }}</span>
            <span v-if="(user.total_amount).max_rate === undefined">0.00</span>

          </b-col>
          <b-col class="text-left" cols="2">
            <span v-if="(user.total_amount).total === undefined"> £0.00</span>
            <span v-else> £{{ (user.total_amount).total }}</span>
          </b-col>
        </b-row>
        <b-row class="ml-0" style="padding:8px 0">
          <b-col class="font-weight-700" cols="5">
            Total
          </b-col>
          <b-col class="font-weight-700" cols="5">
            {{ hourTotal(excludedUsers) }}
          </b-col>
          <b-col class="font-weight-700" cols="2">
            £{{ organizationTotal(excludedUsers) }}
          </b-col>
        </b-row>


      </div>
    </b-modal>
    <!--    <b-button-->
    <!--        v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
    <!--        -->
    <!--        variant="outline-primary"-->
    <!--    >-->
    <!--      Vertically Center-->
    <!--    </b-button>-->
  </div>

</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTable,
  VBModal,
  VBToggle,
  VBTooltip
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import LocationTableToggleRow from '@/views/payments/LocationTableToggleRow'
import vSelect from 'vue-select'
import PracticeStatementApi from '@/apis/modules/practiceStatement'
import Ripple from 'vue-ripple-directive'
import UsersSideBar from '@/views/payments/sidebars/usersSidebar'
import MomentMixin from '@/mixins/MomentMixin'
import moment from 'moment'
// import * as html2canvas from 'html2canvas';
//
import BCardCode from '@core/components/b-card-code/BCardCode'
import Filter from '@/mixins/FilterMixin'

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BOverlay,
    BSidebar,
    UsersSideBar,
    flatPickr,
    LocationTableToggleRow,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    VBTooltip,
    BFormDatepicker
  },
  data () {
    return {
      invoiceDescription: [
        {
          taskTitle: 'testtstst',
          taskDescription: 'testtstst'
        },
        {
          taskTitle: 'testtstst',
          taskDescription: 'testtstst'
        }

      ],
      practiceDetails: [],
      pcn: '',
      test: [],
      practice_excluded_users: [],
      table_refresh_count: 0,
      rowLoading: false,
      includedUsers: [],
      excludedUsers: [],
      SelectedPractice: {},
      SelectedPracticeData: {},
      clickedPracticeId: '',
      selectedStartDate: '',
      selectedEndDate: '',
      formStartDate: moment().startOf('month').format('DD-MM-YYYY'),
      formEndDate: moment().endOf('month').format('DD-MM-YYYY'),
      isUsersSidebarActive: false,
      filters: {},
      jobRoleOption: [],
      tableLoading: false,
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      practices: [],
      fields: [

        {
          key: 'name',
          label: 'Name',
          sortable: false
        },
        {
          key: 'organizationPayment',
          label: 'ORGANISATION PAYMENT',
          sortable: false
        },
        {
          key: 'staffPayment',
          label: 'STAFF PAYMENT',
          sortable: false
        },

        {
          key: 'settings',
          label: ''
        },
        {
          key: 'download',
          label: ''
        },
        {
          key: 'add',
          label: ''
        }
      ]
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
    'b-modal': VBModal
  },
  mixins: [MomentMixin, Filter],

  watch: {
    'formStartDate' (val) {
      if (val !== moment().startOf('month').format('DD-MM-YYYY')) {
        this.tableRefresh()
      }
    },
    'formEndDate' (val) {
      if (val !== moment().startOf('month').format('DD-MM-YYYY')) {
        this.tableRefresh()
      }
    }

  },

  methods: {
    download () {
      // window.print()
      this.printElement(document.getElementById('printEliment'))
    },

    printElement (elem) {
      var domClone = elem.cloneNode(true)

      var $printSection = document.getElementById('printSection')

      if (!$printSection) {
        var $printSection = document.createElement('div')
        $printSection.id = 'printSection'
        document.body.appendChild($printSection)
      }

      $printSection.innerHTML = ''
      $printSection.appendChild(domClone)
      window.print()
    },
    tableRefresh () {

      this.$refs.practice_table.refresh()
    },

    async openUserAddSidebar (item) {
      this.isUsersSidebarActive = !this.isUsersSidebarActive
      if (this.isUsersSidebarActive)
        this.SelectedPractice = item
    },
    async openPrintModel (item) {
      this.$refs['modal-print'].show()

      await this.getPracticeUserData(item)
      await this.getPracticeUserDataExcluded(item)


    },
    async getPcnDetails () {
      try {
        this.pcn = (await PracticeStatementApi.getPcnDetails()).data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    toggleSideBars (val) {
      this.isUsersSidebarActive = !this.isUsersSidebarActive
    },
    async dataRefresh () {
      this.isUsersSidebarActive = false
      await this.getExcludedUsers()
      this.tableRefresh()
      // await this.getPracticeUserDataExcluded( this.SelectedPractice.id)


    },

    async toggleDetails (item) {

      this.practices.forEach((o, i) => {
        if ((this.practices[i].name != item.name)) {
          this.$set(this.practices[i], '_showDetails', false)
        }
      })

      if (item['_showDetails']) {
        this.$set(item, '_showDetails', false)
      } else {
        this.$set(item, '_showDetails', true)
        this.rowLoading = true

        await this.getPracticeUserData(item)
        await this.getPracticeUserDataExcluded(item)
        this.rowLoading = false
      }

    },
    totalHoursCalculation (users) {
      try {
        let total_hours = 0
        users.forEach((o, i) => {
          o.shifts.forEach((m, i) => {
            total_hours = total_hours + (moment(m.time_sheet.end).diff(moment(m.time_sheet.start), 'minutes') / 60)
          })
        })
        return total_hours.toFixed(2)
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    organizationTotal (users) {
      try {
        let total_amount = Number(0)
        users.forEach((o, i) => {
          total_amount = total_amount + Number((o.total_amount).total)
        })
        return total_amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    hourTotal (users) {
      try {
        let total_hours = Number(0)
        users.forEach((o, i) => {
          total_hours = total_hours + Number(o.total_hours)
        })
        return total_hours
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    staffUsers (users) {
      try {
        let total_amount = Number(0)
        users.forEach((o, i) => {
          total_amount = total_amount + Number((o.total_amount).total)
        })
        return total_amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async getExcludedUsers () {
      try {
        const Response = await PracticeStatementApi.getExcludedUsersAllPractices()
        this.practice_excluded_users = Response.data.data.map((x) => ({
          practice_id: x.practice.id,
          name: x.practice.name,
          excluded_users: x.practice.pcn_excluded_users.length > 0 ? x.practice.pcn_excluded_users[0].exclude_user_ids : []
        }))
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    filterQueryUpdate () {
      this.pagination.totalRows = this.pagination.totalRows === 0 ? undefined : this.pagination.totalRows
      this.$refs.practice_table.refresh()
    },

    async loadPractices () {
      try {
        const payload = {
          start_date: moment(this.formStartDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          end_date: moment(this.formEndDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
        }
        this.selectedEndDate = payload.end_date
        this.selectedStartDate = payload.start_date
        const Response = await PracticeStatementApi.getPractices(this.currentPage, 10, payload, this.filterQuery)
        const dataArray = Response.data.data.map((x) => {
          let find = this.practice_excluded_users.find((m) => m.practice_id === x.practice.id)
          let excluded_users = find ? find.excluded_users : []
          return {
            id: x.practice.id,
            owner_name: x.practice.owner.first_name + ' ' + x.practice.owner.last_name,
            name: x.practice.name,
            address: x.practice.address,
            city: x.practice.city,
            postal_code: x.practice.postal_code,
            includedUsers: x.practice.users.filter((k) => {
              return !excluded_users.includes(k.id)
            }).map((l) => {
              return {
                id: x.id,
                name: l.first_name + ' ' + l.last_name,
                total_hours: this.paymentUserHours(l.shifts),
                total_amount: this.paymentUserTotalEarn(l.shifts),
                shifts: l.shifts
              }
            }),
            excludedUsers: x.practice.users.filter((k) => {
              return excluded_users.includes(k.id)
            }).map((l) => {
              return {
                id: x.id,
                name: l.first_name + ' ' + l.last_name,
                total_hours: this.paymentUserHours(l.shifts),
                total_amount: this.paymentUserTotalEarn(l.shifts),
                shifts: l.shifts
              }
            })
          }
        })
        this.practiceDetails = dataArray
        this.tableLoading = false

        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        this.practices = dataArray
        return dataArray

      } catch (e) {
        this.convertAndNotifyError(e)
        this.tableLoading = false
      }
    },
    async getPracticeUserData (item) {
      try {
        this.SelectedPracticeData = item
        this.includedUsers = item.includedUsers
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getPracticeUserDataExcluded (item) {
      try {
        this.excludedUsers = item.excludedUsers
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    }
  },

  async mounted () {
    await this.getExcludedUsers()
    await this.getPcnDetails()
    // Set the initial number of items
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "~@core/scss/base/pages/app-invoice.scss";


@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  //body, html, #wrapper {
  //  width: 100%;
  //}
  body * {
    visibility: hidden;
    width: 100%;
  }
  #printSection, #printSection * {
    visibility: visible;
  }
  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }
  svg {
    margin-top: 10px;
    margin-left: 10px !important;;
  }
}


@media print {

  Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles

}
</style>


<style scoped>
.table.b-table > tbody > tr.b-table-details > td:hover {
  border-top: none !important;
  background-color: white !important;
}

/*//custom align for locations table to meet the collapse alignments*/
.custom_align >>> td:first-child {
  text-align: left;
}

.custom_align >>> th:first-child {
  text-align: left;
}

.custom_align >>> td {
  padding-right: 12px;
}
</style>

