<template>
  <div class="home">

      <!-- Header Bar -->

    <div> <Pheaderbar /> </div>

    <b-card class="mp-0">

      <!-- Search Bar -->

<!--        <Psearchbar/>-->

       <!-- Tabs -->

      <b-tabs >
        <b-tab title="Practices">
          <div class="alert-body"> <Locations /></div>



        </b-tab>

<!--        <b-tab title="Locums">-->
<!--          <div class="alert-body"><Locumes /> </div>-->
<!--        </b-tab>-->
      </b-tabs>
   </b-card>
 </div>
</template>

<script>
import Pheaderbar from './pheaderbar.vue'
import Locumes from './locumes.vue'
import Locations from './locations.vue'
import Psearchbar from './psearchbar.vue'

import { BCard, BTabs , BTab , BSidebar, BButton} from 'bootstrap-vue'

export default {
  name: 'Home',
  components: {
    Pheaderbar,
    Locumes,
    Locations,
    Psearchbar,
    BCard,
    BTabs,
    BTab,
    BSidebar,
    BButton
  },

}
</script>
