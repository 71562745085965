<template>
<b-sidebar
      id="is-users-sidebar-active"
      :visible="isUsersSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-users-sidebar-active', val)  ">
  >
  <template #default="{ hide }">
    <b-overlay
        :show="tableLoading"
        rounded="sm"
    >

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Staff payments list
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>
      <div class="pl-2 pt-2">

        <span class="pl mb-2">Payment for the selected staff members will be made to the practice. If staff prefer to receive payments directly into their accounts deselect the staff member</span>

        <b-card class="mt-1">
          <vue-perfect-scrollbar
              class="todo-task-list-wrapper list-group scroll-area min-vh-50 "
              style=" min-height: 400px"
          >
            <div v-for="user in users" :id="user.id">

              <div class="mb-1">
                <b-row style="margin-top: 2px">
                  <b-col cols="12" md="8">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <b-avatar
                            size="40"
                            variant="light-primary"
                            :src="getUserImage(user.id,false)"
                            class="badge-minimal"

                        />
                      </li>
                      <li class="list-inline-item"> {{ user.name }} </li>

                    </ul>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-checkbox class=""  v-model="user.is_selected" name="check_box" @change="addThisToSelectedList(user)"></b-form-checkbox>
                  </b-col>
                </b-row>


              </div>

            </div>


          </vue-perfect-scrollbar>
        </b-card>

        <div class="d-flex mt-3 mb-1">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="updateService()"
          >
            Save
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="shadow text-primary ml-1"
              type="button"
              variant="outline-white"
              @click="$emit('toggleSideBars')"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-overlay>
  </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BMedia,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTable,
  VBTooltip,
  VBToggle
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Filter from '@/mixins/FilterMixin'
import PracticeStatementApi from "@/apis/modules/practiceStatement";


export default {
  name: 'usersSideBar',

  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BRow,
    BCol,
    BInputGroupPrepend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCard,
    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BImg,
    BMedia,
    BTable,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BOverlay,
    VBTooltip
  },
  mixins: [Filter],

  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  model: {
    prop: 'isUsersSidebarActive',
    event: 'update:is-users-sidebar-active'
  },
  props: {
    isUsersSidebarActive: {
      type: Boolean,
      required: true
    },
    practiceData: {
      type: Object,
      required: true
    },

  },

  data () {
    return {
      checkedStaffMembers:[],
      exclude_user_ids: [],
      users:[],
      hide_btn: true,
      tableLoading: false,
      filters: {},
      selected: [],
      selectedCheck: true,

      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      selectedOption: {
        title: 'All',
        value: 1
      },
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [

        {
          key: 'name',
          label: ' NAME'
        },
        {
          key: 'selected',
          label: ''
        },


      ],
      items: []
    }
  },
  watch: {
    async 'isUsersSidebarActive' (val) {
      if (val){
        await this.getExcludedUsers()
        await this.getPracticeUsers()
      }

    },
    // selected (val) {
    //   // console.log(val)
    // }
  },
  methods: {
    addThisToSelectedList (item) {

      if (_.find(this.selected, {'id': item.id})) {

        _.remove(this.selected, function (e) {
          return e.id === item.id
        })
      } else {
        this.selected.push(item)
      }
      this.hide_btn = this.selected.length === 0
    },
    resetForm () {

    },
    toggle () {
      this.isUsersSidebarActive = false
      // this.selected = []
      // this.filters = {}
      // this.$refs.table.refresh()
    },
    onRowSelected (items) {
      // this.selected = items
    },
    async getPracticeUsers () {
      try {
        this.tableLoading = true
        const Response = await PracticeStatementApi.getPracticeUser(this.practiceData.id)
        const dataArray = await Response.data.data[0].practice.users.map((x) => {
          let obj = {}
          obj.id = x.id
          obj.name = x.first_name +' '+ x.last_name
          obj.is_selected = !this.exclude_user_ids.includes(x.id);
          return obj
        })

        this.tableLoading = false
        this.users = dataArray
        // return dataArray

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

    },

    async getExcludedUsers () {
      try {
        const Response = await PracticeStatementApi.getExcludedUsers(this.practiceData.id)
         this.exclude_user_ids= Response.data.data[0].exclude_user_ids

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

    },
    async updateService () {
      try {
        this.tableLoading = true

        const ids = this.users.filter((x) => !x.is_selected).map((n) => n.id)

        const Response = await PracticeStatementApi.updateExcludedUsers(this.practiceData.id, {user_ids:ids})

        this.showSuccessMessage('Users updated')
        this.$emit('dataRefresh')


        this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }
    },
    filterQueryUpdate () {
      // this.$refs.table.refresh()
    }
  },
  mounted () {

  },


}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  //.table thead th {
  //  border-bottom: 2px solid #ebe9f1;
  //  visibility: hidden !important;
  //}
}
</style>
<style lang="scss" scoped>

.card-body {
  padding: 1px;
}
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

}
</style>

<style lang="scss">
#services_table td:nth-child(2) {
  padding-left: 15px !important;
}
@import "~@core/scss/base/pages/app-todo.scss";
</style>

