<template>
  <div>


      <div class="flex-column flex-md-row  ">

        <h5 class="font-weight-700 ml-2 text-left">Service Charge</h5>
        <div style="background-color:#EFF7FC">
        <b-row class="py-1 ml-0">
          <b-col cols="5">
            47 Crown Street, London W1H 8BR
          </b-col>
          <b-col class="font-weight-700 text-right" cols="2">
            Total hours: 120
          </b-col>
          <b-col class="font-weight-700 text-right" cols="2">
            Charge p/h: £35.00
          </b-col>
          <b-col class="font-weight-700 text-right" cols="2">
            Total: £4,200.00
          </b-col>

        </b-row>
          </div>

        <!--       <table class="w-100 my-2">
                 <tr >
                   <th  class="w-50 font-weight-light">47 Crown Street, London W1H 8BR </th>
                   <th>Total hours: 120</th>
                   <th>Charge p/h: £35.00</th>
                   <th>Total: £4,200.00</th>
                 </tr>
               </table>-->


        <h5 class="font-weight-700 pt-2 ml-2 text-left">Staff Payment</h5>
        <div style="background-color:#EFF7FC" class="py-1">

          <b-row class="py-1 font-weight-700 ml-0">
            <b-col cols="5">
              Clinical pharmacists
            </b-col>
            <b-col class="text-right" cols="2">
              Total hours: 80
            </b-col>
            <b-col class="text-right" cols="2">
              Rate p/h: £50.00
            </b-col>
            <b-col class="text-right" cols="2">
              Total: £4,000.00
            </b-col>
          </b-row>

          <b-row style="padding:8px 0" class="ml-0" :key="item"  v-for="item in [1,2]">
            <b-col cols="5">
              Brooklyn Simmons
            </b-col>
            <b-col class="text-right" cols="2">
             40
            </b-col>
            <b-col class="text-right" cols="2">

            </b-col>
            <b-col class="text-right" cols="2">
              £2,000.00
            </b-col>
          </b-row>

          <b-row class="py-1 font-weight-700 ml-0">
            <b-col cols="5">
              Health and wellbeing coach
            </b-col>
            <b-col class="text-right" cols="2">
             65
            </b-col>
            <b-col class="text-right" cols="2">
             £65.00
            </b-col>
            <b-col class="text-right" cols="2">
               £4,225.00
            </b-col>
          </b-row>

          <b-row style="padding:8px 0" class="ml-0"  :key="item" v-for="item in [1,2,3]">
            <b-col cols="5">
              Brooklyn Simmons
            </b-col>
            <b-col class="text-right" cols="2">
              40
            </b-col>
            <b-col class="text-right" cols="2">

            </b-col>
            <b-col class="text-right" cols="2">
              £2,000.00
            </b-col>
          </b-row>


          <b-row class="py-1 font-weight-700 ml-0">
            <b-col cols="5">
              Physician associate
            </b-col>
            <b-col class="text-right" cols="2">
              150
            </b-col>
            <b-col class="text-right" cols="2">
              £45.00
            </b-col>
            <b-col class="text-right" cols="2">
              £6,750.00
            </b-col>
          </b-row>

          <b-row style="padding:8px 0" class="ml-0" :key="item" v-for="item in [1,2,3,4,5]">
            <b-col cols="5">
              Brooklyn Simmons
            </b-col>
            <b-col class="text-right" cols="2">
              40
            </b-col>
            <b-col class="text-right" cols="2">

            </b-col>
            <b-col class="text-right" cols="2">
              £2,000.00
            </b-col>
          </b-row>

        </div>
      </div>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable
} from 'bootstrap-vue'

export default {
  props: {
    row: {
      required: true,
      type: Object,
    }
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
  },
}

</script>
