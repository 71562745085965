import api from '../axios';
const resource = '/api/pcn'

export default {
  getPractices: (page, paginate, payload, filterQuery, sort = '', desc = true, ) =>
      api.post(`${resource}/practice-statement/get-practice?page=${page}&paginate=
      ${paginate}&sort=${desc ? '' : '-'}${sort}&${filterQuery}` ,payload),


  getPcnDetails: () =>
      api.get(`${resource}/practice-statement/pcn-details`),

  getPracticeUser: (id) =>
      api.get(`${resource}/practice-statement/practice/${id}/users`),

  getExcludedUsers: (id) =>
      api.get(`${resource}/practice-statement/practice/${id}/users/excluded`),
  getExcludedUsersAllPractices: () =>
      api.get(`${resource}/practice-statement/practice/users/excluded`),

  updateExcludedUsers: (id, payload) =>
      api.put(`${resource}/practice-statement/practice/${id}/users/update`, payload),

  getPracticeUserDataIncluded: (id, payload) =>
      api.post(`${resource}/practice-statement/practice/${id}/user-amount/included`, payload),
  getPracticeUserDataExcluded: (id, payload) =>
      api.post(`${resource}/practice-statement/practice/${id}/user-amount/excluded`, payload),


};
