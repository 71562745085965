<template>
  <b-row>

    <b-col cols="12">
      <b-table
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          :items="items"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          hover
          @row-clicked="$router.push('/payments/123/invoice')"
          class="mobile_table_css"
          responsive
      >
             <!-- Name $ Avatar -->

        <template #cell(name)="data,">
           <ul class="list-inline mb-0">
               <li class="list-inline-item">
                 <b-avatar :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')" class="pull-up " />
               </li>
               <li class="list-inline-item"> {{ data.item.name }} </li>
           </ul>
        </template>

            <!-- Paid Unpaid Button -->

        <template #cell(status)="data">
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              plain
              style="background-color:rgba(255, 72, 72, 0.1);"
              text="Unpaid "
              variant="outline-danger">
                 <b-dropdown-item>Unpaid</b-dropdown-item>
                 <b-dropdown-item>Paid</b-dropdown-item>
            </b-dropdown>
        </template>
      </b-table>
    </b-col>


    <!-- Pagination -->

<!--    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" >-->
<!--          <span class="text-muted">Showing 1 to 10 of 187 entries</span>-->
<!--    </b-col>-->

    <!-- Pagination -->

<!--    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" >-->

<!--      <b-pagination-->
<!--          v-model="currentPage"-->
<!--          :per-page="perPage"-->
<!--          :total-rows="totalRows"-->
<!--          class="mb-0 mt-1 mt-sm-0 "-->
<!--          first-number-->
<!--          last-number-->
<!--          next-class="next-item"-->
<!--          prev-class="prev-item">-->

<!--          <template #prev-text>-->
<!--              <feather-icon icon="ChevronLeftIcon" size="18" />-->
<!--          </template>-->

<!--          <template #next-text>-->
<!--             <feather-icon icon="ChevronRightIcon" size="18" />-->
<!--          </template>-->

<!--      </b-pagination>-->

<!--    </b-col>-->
  </b-row>
</template>

<script>

import {BDropdown, BDropdownItem} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'statement',
          label: 'STATEMENT ID',
          sortable: true
        },
        {
          key: 'amount',
          label: 'AMOUNT',
          sortable: true
        },
        {
          key: 'name',
          label: 'PAID TO',
          sortable: true
        },
        {
          key: 'issuedate',
          label: 'ISSUE DATE',
          sortable: true
        },
        {
          key: 'duedate',
          label: 'DUE DATE',
          sortable: true
        },
        {
          key: 'status',
          label: 'STATUS',
        },

      ],
      items: [

        // {
        //   'name': 'Eleanar Pena',
        //   'statement': '#4567890',
        //   'amount': '£1,700.00',
        //   'issuedate': '28/02/2021',
        //   'duedate': '15/03/2021',
        //
        // },
        // {
        //   'name': 'Courtney Henry',
        //   'statement': '#6567898',
        //   'amount': '£3,100.00',
        //   'issuedate': '28/02/2021',
        //   'duedate': '15/03/2021',
        //
        // },
        // {
        //   'name': 'Esther Howard',
        //   'statement': '#2343567',
        //   'amount': '£2,240.00',
        //   'issuedate': '28/02/2021',
        //   'duedate': '15/03/2021',
        //
        // },
        // {
        //   'name': 'Ronald Richards',
        //   'statement': '#6564598',
        //   'amount': '£2,500.00',
        //   'issuedate': '28/02/2021',
        //   'duedate': '15/03/2021',
        //
        // },
        // {
        //   'name': 'Cody Fisher',
        //   'statement': '#4567890',
        //   'amount': '£1,100.00',
        //   'issuedate': '28/02/2021',
        //   'duedate': '15/03/2021',
        //
        // },
        // {
        //   'name': 'Kathryn Murphy',
        //   'statement': '#6567898',
        //   'amount': '£900.00',
        //   'issuedate': '28/02/2021',
        //   'duedate': '15/03/2021',
        //
        // },
        // {
        //   'name': 'Jenny Wilson',
        //   'statement': '#4567890',
        //   'amount': '£1,400.00',
        //   'issuedate': '28/02/2021',
        //   'duedate': '15/03/2021',
        //
        // },
        // {
        //   'name': 'Leslie Alexander',
        //   'statement': '#6567832',
        //   'amount': '£2,450.00',
        //   'issuedate': '28/02/2021',
        //   'duedate': '15/03/2021',
        //
        // },
        // {
        //   'name': 'Jenny Wilson',
        //   'statement': '#4567340',
        //   'amount': '£1,700.00',
        //   'issuedate': '15/02/2021',
        //   'duedate': '01/03/2021',
        //
        // },
        // {
        //   'name': 'Darlene Robertson',
        //   'statement': '#6567154',
        //   'amount': '£890.00',
        //   'issuedate': '15/02/2021',
        //   'duedate': '01/03/2021',
        //
        // },
        // {
        //   'name': 'Eleanar Pena',
        //   'statement': '#4563490',
        //   'amount': '£1,700.00',
        //   'issuedate': '15/02/2021',
        //   'duedate': '01/03/2021',
        //
        // },
        // {
        //   'name': 'Mildred Mcleod',
        //   'statement': '#6567898',
        //   'amount': '£1,980.00',
        //   'issuedate': '15/02/2021',
        //   'duedate': '01/032021',
        //
        // },
      ]
    }
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },

}
</script>
<style>
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}
</style>
