<template>
  <div class="row">

         <!-- Search Button -->

      <b-col cols="12" md="4" class="mb-1">
         <b-form-group>
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="icons-search"
            placeholder="Search"
          />
        </b-input-group>
      </b-form-group>
      </b-col>

         <!-- Select Location  -->

      <b-col cols="12" md="2">
                <b-form-group>
                    <v-select
                            
                            label="title1"
                            placeholder="All Locations"
                            :options="[
                            {title1:'United Kingdom', value:'hdhdh'},
                            {title1:'United States', value:'hdhdh'},
                            {title1:'Sri Lanka', value:'hdhdh'},
                            ]"
                    />
                </b-form-group>
      </b-col>

          <!-- Create Programme Button -->

    <b-col cols="12" md="4" ></b-col>

    <b-col cols="12" md="2" class="flex-float-right">
        <b-form-group>
               <v-select
                    placeholder="February, 2021"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title1"
                    :options="option1"
              />
        </b-form-group>
    </b-col>
  </div>
</template>


<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { BInputGroup, BFormInput, BInputGroupPrepend, BRow, BButton, BCol,
BFormGroup, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormGroup,
     BListGroup,
     BListGroupItem,
     vSelect,

  },
  directives: {
     Ripple,
  },

  data() {
    return {
      option1: [{title1: 'February, 2021'},{title1: 'January, 2021'},{title1: 'December, 2020'},{title1: 'November, 2020'},{title1: 'Octomber, 2020'},],

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

